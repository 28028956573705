import React, {useRef, useState} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import './inputs.css'
import * as XLSX from "xlsx";
import {sheet2arr} from "./utils/readerUtils";
import TextFilesInput from "./components/textFilesInput";

function Inputs(props) {

    let textFileHeaders = [[["BOK", "NR EWID.", "MOD LICZ", "PRĄD", "FAZ", "NAP", "MNOŻNA", "NR", "ROK Ostatnniej LEGAL.", "PRZYŁĄCZE", "POSTARUNEK", "ODBIORCA", "1", "2", "3", "4", "Legalizacja", "5"]]];

    const xlsFile = useRef(null);
    const [delimiter, setDelimiter] = useState("");
    const [delimiterSet, setDelimiterSet] = useState(false);
    const [filesMerged, setFilesMerged] = useState(false);
    const [loadProgress, setLoadProgress] = useState(0.0);
    const [mergeProgress, setMergeProgress] = useState(0.0);
    const [csvArray, setCsvArray] = useState([]);
    const [xslArray, setXslArray] = useState([]);

    function readXlsFiles(fileInput) {
        // setFilesMerged(false);
        // numberOfFilesLoaded = 0;
        // setLoadProgress(0.0);
        let files = fileInput.files;
        // numberOfFilesUploaded = files.length;
        setXslArray([]);
        for (let i = 0; i < files.length; i++) {
            readXlsFile(files[i]);
        }
    }

    function readXlsFile(file) {
        const reader = new FileReader();
        reader.onload = function () {
            let workbook = XLSX.read(reader.result);
            console.log(workbook);
            let firstSheetName = workbook.SheetNames[0];
            let array = sheet2arr(workbook.Sheets[firstSheetName]);
            console.log(array);
            setXslArray(xslArray => [...xslArray, array]);
            // var delimiterValue = delimiter;
            // var processed = processData(reader.result, delimiterValue);
            // setCsvArray(csvArray => [...csvArray, processed]);
            // numberOfFilesLoaded++;
            // if (numberOfFilesLoaded >= numberOfFilesUploaded) {
            //     setFilesMerged(true);
            // }
            // setLoadProgress(numberOfFilesLoaded / numberOfFilesUploaded * 100);
        };
        reader.readAsArrayBuffer(file);
    }

    function downloadCSV() {
        console.log(xslArray);
        let workArray = csvArray.concat(xslArray);
        console.log(workArray);
        setMergeProgress(0.0);
        const element = document.createElement("a");
        let fileAsText = "";
        for (let i = 0; i < workArray.length; i++) {
            setMergeProgress(i / workArray.length * 100);
            for (let j = 0; j < workArray[i].length; j++) {
                if (j % 500 === 0) {
                    setMergeProgress((i / workArray.length * 100) + j / workArray[i].length * 100 / workArray.length);
                }
                for (let k = 0; k < workArray[i][j].length; k++) {
                    let value = workArray[i][j][k];
                    if (value !== undefined) {
                        if (value.includes(delimiter)) {
                            value = JSON.stringify(value);
                        }
                        fileAsText += value;
                    }
                    if (k !== workArray[i][j].length - 1) {
                        fileAsText += delimiter;
                    }
                }
                fileAsText += "\r\n";
            }
        }
        setMergeProgress(100);

        const file = new Blob([fileAsText], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "merged.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    function enableFileInput(event) {
        let value = event.target.value;
        setDelimiter(value);
        if (!value || /^\s*$/.test(value)) {
            setDelimiterSet(false);
        } else {
            setDelimiterSet(true);
        }
    }

    return (
        <div className="inputs-container">
            <div className="inputs">
                <div className="component-container">
                    <label htmlFor="delimiter">Delimiter: </label>
                    <input type="text" id="delimiter" onChange={event => enableFileInput(event)}/>
                </div>
                <div className="component-container">
                    <TextFilesInput delimiter={delimiter} delimiterSet={delimiterSet} textFileHeaders={textFileHeaders} isFirstInput={true} setCSVFileFunction={setCsvArray} setFilesMergedFunction={setFilesMerged} />
                    <TextFilesInput delimiter={delimiter} delimiterSet={delimiterSet} textFileHeaders={textFileHeaders} isFirstInput={false} setCSVFileFunction={setCsvArray} setFilesMergedFunction={setFilesMerged} />
                    <TextFilesInput delimiter={delimiter} delimiterSet={delimiterSet} textFileHeaders={textFileHeaders} isFirstInput={false} setCSVFileFunction={setCsvArray} setFilesMergedFunction={setFilesMerged} />
                    <TextFilesInput delimiter={delimiter} delimiterSet={delimiterSet} textFileHeaders={textFileHeaders} isFirstInput={false} setCSVFileFunction={setCsvArray} setFilesMergedFunction={setFilesMerged} />
                </div>

                <label htmlFor="file_xls">XLS: </label>
                <input multiple={true} type="file" id="file_xls" ref={xlsFile} onChange={() => readXlsFiles(xlsFile.current)}/>

                <button disabled={!filesMerged} type="button" onClick={downloadCSV}>download</button>
            </div>
            <ProgressBar now={loadProgress} striped={true} animated={true} label={`loading: ${loadProgress}%`} className="progress-container"/>
            <ProgressBar now={mergeProgress} striped={true} animated={true} label={`merging: ${mergeProgress}%`} className="progress-container"/>
        </div>
    );
}

export default Inputs;
