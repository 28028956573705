import * as XLSX from "xlsx";

export function sheet2arr(sheet) {
    let result = [];
    let row;
    let rowNum;
    let colNum;
    let range = XLSX.utils.decode_range(sheet["!ref"]);
    for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
        row = [];
        for(colNum = range.s.c; colNum <= range.e.c; colNum++){
            let nextCell = sheet[
                XLSX.utils.encode_cell({r: rowNum, c: colNum})
                ];
            if( typeof nextCell === 'undefined' ){
                row.push(void 0);
            } else row.push(nextCell.w);
        }
        result.push(row);
    }
    return result;
}

export function processData(allText, delimiter, year) {
    var allTextLines = allText.split(/\r\n|\n/);
    var lines = [];

    for (var i=0; i<allTextLines.length - 1; i++) {
        var data = allTextLines[i].split(delimiter);
        var tarr = [];
        for (let j=0; j<data.length; j++) {
            if (j === 1) {
                tarr[0] = data[j].substring(0, 2);
            }
            tarr.push(data[j]);
        }
        // this is some empty columns requirement (there are actually 4, but source system produces additional delimiter at the end of the line -_-
        for (let j=0; j<3; j++) {
            tarr.push("");
        }
        tarr.push(year);
        tarr.push("");
        // end of that requirement
        lines.push(tarr);
    }
    return lines;
}