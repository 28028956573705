import React, {useRef, useState} from 'react';
import {processData} from "../utils/readerUtils";
import ProgressBar from "react-bootstrap/ProgressBar";
import './textFilesInput.css'

function TextFilesInput({ delimiter, delimiterSet, textFileHeaders, isFirstInput, setCSVFileFunction, setFilesMergedFunction }) {

    const csvFile = useRef(null);
    let numberOfFilesLoaded = 0;
    let numberOfFilesUploaded = 0;

    const [loadProgress, setLoadProgress] = useState(0.0);
    const [year, setYear] = useState("2022");

    function readFiles(fileInput, isResetMergedFile) {
        setFilesMergedFunction(false);
        numberOfFilesLoaded = 0;
        setLoadProgress(0.0);
        let files = fileInput.files;
        numberOfFilesUploaded = files.length;
        if (isResetMergedFile) {
            setCSVFileFunction(textFileHeaders);
        }
        for (let i = 0; i < files.length; i++) {
            readFile(files[i]);
        }
    }

    function readFile(file) {
        const reader = new FileReader();
        reader.onload = function () {
            let delimiterValue = delimiter;
            let processed = processData(reader.result, delimiterValue, year);
            setCSVFileFunction(csvArray => [...csvArray, processed]);
            numberOfFilesLoaded++;
            if (numberOfFilesLoaded >= numberOfFilesUploaded) {
                setFilesMergedFunction(true);
            }
            setLoadProgress(numberOfFilesLoaded / numberOfFilesUploaded * 100);
        };
        reader.readAsBinaryString(file);
    }


    return (
        <div className="input">
            <div>
                <input multiple={true} type="file" ref={csvFile} onChange={() => readFiles(csvFile.current, isFirstInput)} disabled={!delimiterSet}/>
                <label htmlFor="year">Rok: </label>
                <input type="text" id="year" onChange={(event) => setYear(event.target.value)} value={year}/>
            </div>

            <ProgressBar now={loadProgress} striped={true} animated={true} label={`loading: ${loadProgress}%`} className="progress-container"/>
        </div>

    );
}

export default TextFilesInput;